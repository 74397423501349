import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const GatsbyImgae = ({
  filename,
  alt,
  ...props
}) => (
      <StaticQuery
          query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                  fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}

      render={(data) => {
          data.images.edges.forEach(n =>
            console.log(n.node.relativePath)
          )
        
          const image = data.images.edges.find(n =>
              n.node.relativePath.includes(filename)
          )

          if (!image) {
              return null
          }

          return (
              <Img
                  alt={alt}
                  fluid={{ ...image.node.childImageSharp.fluid }}
                  {...props}
              />
          );
      }}
  />
);

export default GatsbyImgae;