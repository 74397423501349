import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import TestimonialSection from '../Testimonial';
import GatsbyImgae from '../GatsbyImage';
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {
  BookSectionItem,
  PortfolioLink,
} from './bookSection.style';

import styled from 'styled-components';
export const Thumb = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: -5.818px 10.495px 50px 0px rgba(101, 106, 160, 0.43);
  float: left;
  margin: 0 15px 15px 0;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;


const BookSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
  twitterProfileName
}) => {
  return (
    <Box {...sectionWrapper} as="section" id="book_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Book" />
        </Box>
        
        <BookSectionItem>
            <Box {...portfolioImage}>
                <OutboundLink href="https://peaks.cc/susieyy/iOS_architecture" target="_blank" rel="noopener noreferrer">
                    <GatsbyImgae filename={'book.png'} />
                </OutboundLink>
            </Box>
            <Box {...portfolioDetails}>
                <PortfolioLink>
                    <OutboundLink href="https://peaks.cc/susieyy/iOS_architecture" target="_blank" rel="noopener noreferrer">
                        <Heading content="iOSアプリ設計パターン入門" {...titleStyle} />
                    </OutboundLink>                    
                </PortfolioLink>
                <Text content="設計を知る、パターンを知る、導入する" {...detailsStyle} />
                <Text content="本書はiOSアプリにおける設計について解説した入門書です。 MVCやMVVMなどの代表的なアーキテクチャパターンの解説にとどまらず、そもそも設計とは何か？という視点から各パターンを紹介し、そして実際の現場でどのように適用されているかを解説しています。" {...detailsStyle} />              
                <Text content="Reduxアーキテクチャについての章を執筆しました。" {...detailsStyle} />
                <Box>
                  <GatsbyImgae filename={'authors.png'} width={ '120px' } />                
                </Box>
            </Box>        
        </BookSectionItem>     
      </Container>
      <TestimonialSection />
    </Box>
  );
};

BookSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

BookSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '150px'],
  },
  secTitleWrapper: {
    width: ['100%', '100%', '60%', '50%', '50%'],
    mb: '40px'
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'left',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
    verticalAlign: 'top'
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '38px', '38px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    marginBottom: '20'
  },

  twitterProfileImage: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    overflow: 'hidden',
    //box-shadow: -5.818px 10.495px 50px 0px rgba(101, 106, 160, 0.43);
    float: 'left',
    margin: '0 15px 15px 0',
      img: {
      width: '100%',
      height: 'auto',
      display: 'bloc'
    }    
  },
  twitterProfileName: {
    display: 'inline-block',
    verticalAlign: 'middle'
  }
};

export default BookSection;
